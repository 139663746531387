import Constants from './constants.js';
import Currencies from './Common-Currency.json';
export default {
	installed: true,
	name: 'globals',
	install(Vue) {
		Vue.prototype.$constants = Constants;
		Vue.prototype.$authCode = undefined;
		Vue.prototype.$currencies = Currencies;
		//Vue.prototype.$apiUrl = document.location.origin.replace('8080','8282') + '/api';
		Vue.prototype.$apiUrl = 'https://api.youinvoice.net/api';
			/**
		 * Verifies if the parameter is null or undefined
		 * @param  {any}  p 
		 * @return {Boolean}   
		 */
		Vue.prototype.$isNullOrUndefined = function(p){
			return (p === undefined) || (p === null);
		}

		/**
		 * Verifies if the parameter is null, undefined or an empty string
		 * @param {string} s
		 * @returns {boolean}
		 */
		Vue.prototype.$isEmptyString = function(s){
			return (this.$isNullOrUndefined(s) ? true : s.length === 0);
		}

		/**
		 * Verifies if the parameter is null, undefined or an empty string and returns false if it has no value
		 * @param  {any}  data
		 * @return {Boolean}      
		 */
		Vue.prototype.$isValid = function(data){
			return this.$isNullOrUndefined(data) ? false : !(data.length === 0);
		}
		/**
		 * Converts an object of objects to a object array
		 * @param  {Object} o
		 * @return Array
		 */
		Vue.prototype.$toObjectArray = function(o){
			return Object.keys(o).map(key => { return o[key]; });
		}
		/**
		 * Returns cookie value by name
		 * @param  {string} name [description]
		 * @return string
		 */
		Vue.prototype.$getCookieValue = function(name) {
			let c = document.cookie.split(';').filter(f => {
				return f.indexOf(name) > -1 
			})
			if(c.length === 0){
				return null
			}

			c = c[0].trim().split('=');
			return c[1];
		}
		/**
		 * Deletes all the cookies
		 * @return void
		 */
		Vue.prototype.$deleteAllCookies = function(){
			let cookies = document.cookie.split(';');
			let expirationDate = new Date(1);
			for(let i = 0;i < cookies.length; i++){
				let name = cookies[i].split('=')[0];
				document.cookie = name + '="";expires=' + expirationDate;
			}
		}
		/**
		 * [dataURLtoFile description]
		 * @param  {string} dataURL  The URL containing the file
		 * @param  {string} fileName The given name of the file
		 * @return {File}          [description]
		 */
		Vue.prototype.$dataURLtoFile = function (dataURL, fileName) {
			let arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
			while(n--){
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], fileName, {type:mime});
		}
		/**
		 * Sets authorization code
		 */
		Vue.prototype.$setAuthCode = function (){
			this.$authCode = this.$getCookieValue('authCode');
			// console.log(this.$authCode)
		}
		/**
		 * Formats date to specified format
		 * @param  {Date} date
		 * @param  {string} format
		 * @return string        
		 */
		Vue.prototype.$formatDate = function(date, format = 'en-us'){
			let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			let d = new Date(date);
			const lang = this.$getCookieValue('lang') === 'es' ? 'es-pr' : format;

			return d.toLocaleDateString(`${lang}-`, options);
		}

		Vue.prototype.$ucfirst = function(str){
			return str[0].toUpperCase() + str.slice(1);
		}

		Vue.prototype.$executeFunctionByName = function(context, funcName){
			context[funcName]();
		}
	}
};
