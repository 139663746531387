import Vue from 'vue';
import Globals from '@/plugins/globals';
import Router from 'vue-router';

import Invoice from '@/components/invoice/create/invoice-view.vue';
import Faq from '@/components/faq-view/faq-view.vue';
import NotFound from '@/views/404'
import Registration from '@/components/auth/registration-view/registration-view.vue';
import Login from '@/components/auth/login-view/login-view.vue';
// import Reset from '@/components/auth/reset/send-email/send-email.vue';
import Profile from '@/components/profile-view/profile-view.vue';
import EmailVerification from '@/components/auth/email-verification/email-verification.vue'

Vue.use(Globals);
Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Invoice,
    props: { isTemplate: false },
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    // beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    // beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/register',
    name: 'register',
    component: Registration,
    beforeEnter: (to, from, next) => { next(); }
  },
  {
    path: '/emailVerification/:emailVerificationToken',
    name: 'emailVerification',
    component: EmailVerification,
    props: true
  },
  // {
  //   path: '/reset',
  //   name: 'reset',
  //   component: Reset,
  //   beforeEnter: (to, from, next) => { next(); }
  // },
  // {
  //   path: '/invoice',
  //   name: 'invoice',
  //   component: Invoice,
  //   props: { isTemplate: false },
  //   beforeEnter: (to, from, next) => { next(); }
  // },
  // {
  //   path: '/template/update/:id',
  //   name: '/templateupdateById',
  //   component: Invoice,
  //   props: { isTemplate: true, updateTemplate: true }
  // },
  // {
  //   path: '/template/new',
  //   name: '/templateNew',
  //   component: Invoice,
  //   props: { isTemplate: true, newTemplate: true }
  // },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    // beforeEnter: (to, from, next) => { next(); }
  },
  {
      path: '/*',
      name: 'NotFound404',
      component: NotFound
  }
]

export default new Router({
  mode: 'history',
  routes: routes
})
