import NotificationBus from '@/notification-bus.js';
import CreditCard from "@/models/CreditCard";
// import Response from "@/models/Response";
// import VueRecaptcha from 'vue-recaptcha';
// import Globals from '../../globals.js';

export default{
	mounted(){
		this.cycleOnChange()
	},
	// components: { VueRecaptcha },
	data(){
		return{
			isSubscribing: false,
			captcha: '',
			paymentTypeEnum: Object.freeze({"creditCard": 0, "paypal": 1}),
			type: 0,
			cycle: 'm',
			cyclePrice: 0.00,
			email: '', //'sfb.brian@gmail.com',
			name: '', //'Brian Melendez',
			password: '', //'password1',
			password_confirmation: '', //'password1',
			card: new CreditCard(),
			// {
			// 	cardholderName: 'Brian Melendez',
			// 	number: '4111111111111111',
			// 	cvv: '111',
			// 	expirationMonth: '06',
			// 	expirationYear: '2025'
			// },
			notificationMessage: '',
			errors: {
				name: false,
				email: false,
				password: false,
				password_confirmation: false,
				cardholderName: false,
				number: false,
				cvv: false,
				expirationMonth: false,
				expirationYear: false,
				captcha: false
			}
		}
	},
	methods:{
		cycleOnChange: function(){
			switch(this.cycle){
				case 't':
					this.cyclePrice = 0.00;
					break;
				case 'm':
					this.cyclePrice = 3.00;
					break;
				case 'y':
					this.cyclePrice = 30.00;
					break;
				default:
					this.cycle = 'm';
					this.cyclePrice = 3.00;
					break;
			}
		},

		setType: function(newType){
			this.type = newType;
		},

		setNotificationMessage: function(message, newLine = true){
			if(!this.notificationMessage.includes(message)){
				if(newLine && this.notificationMessage.length > 0) {
					this.notificationMessage += '\\n';
				}
				this.notificationMessage += message;
			}
		},

		hasValidEmail: function(){
			let flag = this.isValid(this.email) && this.email.includes('@') && this.email.includes('.');
			if(!flag){
				this.setNotificationMessage('Invalid Email');
			}
			return flag;
		},

		hasValidPassword: function(){
			let flag = this.isValid(this.password) && (this.password.length > 8);
			if(!flag){
				this.setNotificationMessage('Invalid Password');
			}
			return flag;
		},

		hasValidConfirmPassword: function(){
			let flag = this.isValid(this.password_confirmation) && (this.password_confirmation === this.password);
			if(!flag){
				this.setNotificationMessage('Passwords do not match');
			}
			return flag;
		},

		hasValidCardExpirationMonth: function(){
			let date = new Date();
			let flag = (this.card.expirationMonth.length < 3)
							&& (this.card.expirationMonth > 0 && this.card.expirationMonth < 13)
			
			if(!flag){
				this.setNotificationMessage('The month is invalid');
			}

			if(this.card.expirationYear < date.getFullYear()) return false;
			if(this.card.expirationYear === date.getFullYear() && this.card.expirationMonth > date.getMonth() + 1)
				return false 
			if(this.card.expirationYear > date.getFullYear()) return true
			
			flag = flag && (this.card.expirationYear >= date.getFullYear()
							&& this.card.expirationMonth >= date.getMonth());
			if(!flag && this.card.expirationMonth.length > 0) {
				this.setNotificationMessage('The card is expired');
			}
			return flag;
		},

		hasValidCardExpirationYear: function(){
			let flag = false;
			let date = new Date();
			flag = (this.card.expirationYear.length < 5 && this.card.expirationYear.length > 0)
			if(!flag){
				this.setNotificationMessage('The year is invalid')
			}
				
				flag = flag && (this.card.expirationYear >= date.getFullYear());
			if(!flag && this.card.expirationYear.length > 0) {
				this.setNotificationMessage('The card is expired')
			}
			
			return flag;
		},

		hasValidCardNumber: function(){
			let flag = this.card.number.length >= 15 && this.card.number.length <= 16
					// && ((this.card.cvv.length == 3) && (this.card.number.length < 17))
					// && ((this.card.cvv.length == 4) && (this.card.number.length < 16));
			if(!flag){
				this.setNotificationMessage('Card number is invalid');
			}
			return flag;
		},

		hasValidCvv: function(){
			let flag = this.card.cvv.length >= 3 && this.card.cvv.length <= 4
					// && ((this.card.cvv.length == 3) && (this.card.number.length < 17))
					// && ((this.card.cvv.length == 4) && (this.card.number.length < 16));
			if(!flag){
				this.setNotificationMessage('CVV is invalid');
			}
			return flag;
		},

		validateFields: function(){
			this.isSubscribing = true;
			this.notificationMessage = '';
			this.errors.name = !this.isValid(this.name);
			if(this.errors.name) 
				this.setNotificationMessage('Invalid name');
			this.errors.email = !this.hasValidEmail();
			this.errors.password = !this.hasValidPassword();
			this.errors.password_confirmation = !this.hasValidConfirmPassword();
			
			this.errors.cardholderName = !this.isValid(this.card.cardholderName);
			if(this.errors.cardholderName) 
				this.setNotificationMessage('Invalid card holder name');
			this.errors.number = !this.hasValidCardNumber();
			this.errors.cvv = !this.hasValidCvv();
			this.errors.expirationMonth = !this.hasValidCardExpirationMonth();
			this.errors.expirationYear = !this.hasValidCardExpirationYear();
			if(this.captcha.length === 0){
				this.errors.captcha = true;
				this.setNotificationMessage('You need to prove you are not a robot. Or are you?')
			} else {
				this.errors.captcha = false;
			}
			// card: {
			// (this.card.cardholderName.length > 0) ? true : false;
			// (this.card.cvv.length < 5) ? true : false;
			// }

			if(Object.values(this.errors).indexOf(true) > -1) {
				NotificationBus.$emit('eventFlashMessage',{
					id: 0,
					message: this.notificationMessage,
					type: 'danger',
					autoClose: false,
					clearList: true
				});
				this.isSubscribing = false;
			}
			else{
				this.verifyPayment();
				// this.isSubscribing = false;
			}
		},

		onCaptchaVerified: function(event){
			//console.log('onCaptchaVerified', event)
			this.captcha = event;
		},

		onCaptchaExpired: function(/*event*/){
			this.captcha = '';
		},

		/**
		* Register user, then pay and redirect to profile-view with payment-modal response
		**/
		verifyPayment: function(){
			let body = {
				amount: this.cyclePrice,
				email: this.email,
				card: this.card
			};

			// var headers = {
			// 	'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			// };
			
			this.$http.post('/payment-modal/braintree/customer/create', body, {
				headers: {
					// 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then( (response) => {
				//console.log(response)
				this.isSubscribing = false;
				if(response.body.succeeded){
					if(response.body.data.transaction.transaction.status === 'authorized'){
						//console.log('response', response)
						this.registerUser(response.body.data.transaction.transaction.customer.id);
					}
				}
				else{
					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						title: 'Warning',
						message: response.body.message,
						type: 'warning',
						autoClose: false,
						clearList: true
					});
				}
			}, (error) => {
				this.isSubscribing = false;
				NotificationBus.$emit('eventFlashMessage',{
                    id: 0,
                    title: 'Error',
                    message: error.body.message,
                    type: 'danger',
                    autoClose: true,
                    clearList: true
				});
			})	
		},

		registerUser: function(){
			this.isSubscribing = true;

			let url = this.$apiUrl + '/register';
			let user = {
				name: this.name,
				email: this.email,
				password: this.password,
				// cardName: this.card.cardholderName,
				// cardNumber: this.card.number,
				// securityCode: this.card.cvv,
				// expDate: this.card.expirationMonth + '/' + this.card.expirationYear,
				// cycle: this.cycle,
				// amount: this.cyclePrice
			}

			this.$http.post(url, {user}, {
				headers: {
					// 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(response => {
				this.isSubscribing = false;

				if(response.body.succeeded) {
					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						message: 'REGISTRATION_SUCCESS',
						type: 'success',
						autoClose: true,
						clearList: false
					});
				}
				else {
					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						message: this.$t(response.body.message),
						type: 'danger',
						autoClose: false,
						clearList: false
					});
				}
				// window.location.assign(window.location.protocol + '//' + window.location.host + '/');
			}).catch(() => {
				this.isSubscribing = false;
				NotificationBus.$emit('eventFlashMessage',{
					id: 0,
					message: '', //error.body.errors.email.join('\\n'),
					type: 'danger',
					autoClose: false,
					clearList: true
				});
			});
		},

		/**
		 * Decide si un elemeneto debe estar desabilitado
		 *
		 * @return {object}     
		 */
		disabler: function(){
			return {
				'disabled': false
			}
		},

		/**
		 * Devuelve clases para activar el "spìnner" en un elemento
		 * 
		 * @param  {boolean} flag
		 * @return {string}     
		 */
		spin: function(flag){
			if(flag) return 'fa-spinner fa-spin'
			else return ""
		}
	}
}