export default class CreditCard 
{
	contructor(name, last4, exp_month, exp_year, brand)
	{
		this.name = name;
		this.last4 = last4;
		this.exp_month = exp_month;
		this.exp_year = exp_year;
		this.brand = brand;

		this.cardholderName = '';
		this.number = '';
		this.cvv = '';
		this.expirationMonth = '';
		this.expirationYear = '';
    }
}