import NotificationBus from '@/notification-bus.js';
import InputError from '@/models/InputError.js';
import GlobalEventBus from '@/event-bus.js';
 
export default {
	beforeRouteLeave(to, from, next){
		this.$setAuthCode();
		next();
	},
	beforeRouteEnter(to, from,next){
		next();
	},
	mounted () {
		if (this.$getCookieValue('authCode') !== null) {
			this.validateAuthCode();
		} else {
			this.show = true;
		}
	},
	data () {
		return {
			show: false,
			errors: {
				email: new InputError(),
				password: new InputError(),
			},
			formData: {
				email: '',
				password: '',
				rememberMe: false
			},
			isLoginIn: false
		};
	},
	methods: {
		/**
		 * Logs user to the application
		 * @return void
		 */
		login: function() {
			this.isLoginIn = true;
			if(!this.validateLogin()){
				let url = this.$apiUrl + '/login';
				let options = {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				};
				this.$http.post(url, {user:this.formData}, options).then(res => {
					this.isLoginIn = false;
					if(res.body.succeeded){
						let isSessionCookie = res.body.data.api_token_expiration_date == null;
						let cookie = `authCode=${res.body.data.api_token}`;// + ';samesite=strict';
						if(!isSessionCookie) {
							cookie += `;expires=${new Date(res.body.data.api_token_expiration_date)}`;
						}
						else {
							let expDate = new Date();
							expDate.setHours(expDate.getHours() + 4)
							cookie += `;expires=${expDate}`;
						}
						document.cookie = cookie;
						document.cookie = `user=${btoa(JSON.stringify(res.body.data.user))}`;// + ';samesite=strict';
						GlobalEventBus.$emit(this.$constants.eventNames.loginSuccess);
						this.$authCode = res.body.data.api_token;
						this.$router.push('profile');
					}
					else {
						NotificationBus.$emit('eventFlashMessage',{
							id: 0,
							title: 'Animal',
							message: this.$t('errors.invalidCredentials'),
							type: 'danger',
							autoClose: false,
							clearList: true
						});
					}
				}).catch(err => {
					this.isLoginIn = false;
					// console.log(err);
					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						title: 'Error',
						message: JSON.stringify(err),
						type: 'danger',
						autoClose: false
					});
				});
			}
		},
		/**
		 * [validateLogin description]
		 * @return {[type]} [description]
		 */
		validateLogin: function(){
			let errorArray = [];
			this.errors.email = new InputError(false, '');
			this.errors.password = new InputError(false, '');

			if(!this.$isValid(this.formData.email)) {
				this.errors.email.message = this.$t('errors.emptyEmail');
				this.errors.email.hasError = true;
			}

			if(!this.$isValid(this.formData.password)) {
				this.errors.password.message = this.$t('errors.emptyPassword');
				this.errors.password.hasError = true;
			}

			errorArray = this.$toObjectArray(this.errors);
			
			if(errorArray.filter(e => e.hasError).length > 0){
				let message = errorArray.filter(e => e.hasError).map(m => m.message).join('\\n');
				// console.log(message)
				NotificationBus.$emit('eventFlashMessage',{
					id: 0,
					title: 'Error',
					message: message,
					type: 'danger',
					autoClose: false,
					clearList: true
				});
			}
			return errorArray.filter(e => e.hasError).length > 0;
		},

		validateAuthCode: function(){
			let url = this.$apiUrl + '/validateAuthCode';
			let options = {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			};
			let user = JSON.parse(atob(this.$getCookieValue('user')));
			if (!(this.$isNullOrUndefined(user) && this.$isNullOrUndefined(this.$getCookieValue('authCode'))) ){
				let data = {email: user.email, authCode: this.$getCookieValue('authCode')};
				this.$http.post(url, { data: data }, options).then(res => {
					if (res.body.succeeded)
						this.$router.push('/profile');
					this.show = true;
				});
			}
		}
	},
	computed: {
		/**
		 * [inputError description]
		 * @param  {[type]} field [description]
		 * @return {[type]}       [description]
		 */
		inputError: function(field) {
			return {
				'input-error': field.hasError
			}
		}
	}
}